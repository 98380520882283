import { post, get ,put ,del } from "./axios.js";
let api = {
//获取平台信息
getPlatformInfo: json => {
        return get("/client/platform/info/getPlatformInfo", json);
},
//查询头部导航信息
findHeadNavigation: json => {
    return get("/client/head/navigation/findHeadNavigation", json);
},
//获取首页banner图
    getHomeBanner: json => {
    return get("/client/home/banner/getHomeBanner", json);
},  
//获取首页信息
getHomeInfo: json => {
    return get("/client/home/getHomeInfo", json);
},  
//获取底部二维码
getBottomQrCode: json => {
    return get("/client/home/qr/code/getBottomQrCode", json);
}, 
//获取集团站群
getSiteGroup: json => {
    return get("/client/home/site/group/getSiteGroup", json);
}, 
//获取检查举报
getSupervisoryReport: json => {
    return get("/client/home/supervisory/report/getSupervisoryReport", json);
}, 
//获取我们集团简介
getWeGroupProfile: json => {
    return get("/client/we/group/profile/getWeGroupProfile", json);
},
//获取集团架构信息
getWeGroupStructure: json => {
    return get("/client/we/group/structure/getWeGroupStructure", json);
},
//获取发展历程信息
getDevelopmentHistory: json => {
    return get("/client/we/development/history/getDevelopmentHistory", json);
},
//获取荣誉资质
getHonorQualification   : json => {
    return get("/client/we/honor/qualification/getHonorQualification", json);
},
//获取荣誉信息
getHonor: json => {
    return get("/client/we/honor/getHonor", json);
},
//获取建筑工程信息
getArchitectureInfo: json => {
    return get("/client/architecture/getArchitectureInfo", json);
    },
//分页查询全国业务分布
getPageDistributionContent: json => {
    return get("/client/architecture/getPageDistributionContent", json);
},
//分页获取工程案例
getPageEngineeringCase: json => {
    return get("/client/architecture/getPageEngineeringCase", json);
},
//获取推荐案例
getRecommendEngineeringCase: json => {
    return get("/client/architecture/getRecommendEngineeringCase", json);
    },
//获取案例详情
getEngineeringCaseInfo: json => {
    return get("/client/architecture/getEngineeringCaseInfo", json);
},
//获取供应链信息
getSupplyChainInfo: json => {
    return get("/client/supply/chain/getSupplyChainInfo", json);
},
//获取产业教育信息
getIndustrialEducationInfo: json => {
    return get("/client/industrial/education/getIndustrialEducationInfo", json);
},
//获取推荐课程
getRecommendIndustrialEducation: json => {
    return get("/client/industrial/education/getRecommendIndustrialEducation", json);
},
//获取品牌释义信息
getBrandingIntro: json => {
    return get("/client/culture/getBrandingIntro", json);
},  
//获取企业文化信息
getCorporateCulture: json => {
    return get("/client/culture/getCorporateCulture", json);
}, 
//获取推荐文化活动
getRecommendCorporateActivity: json => {
    return get("/client/culture/getRecommendCorporateActivity", json);
    }, 
//获取文化活动详情
getCorporateActivityInfo: json => {
    return get("/client/culture/getCorporateActivityInfo", json);
}, 
//分页获取新闻
getPageNews: json => {
    return get("/client/news/getPageNews", json);
    }, 
//获取新闻详情
getNewsInfo: json => {
    return get("/client/news/getNewsInfo", json);
},
//获取推荐新闻
getRecommendedNews: json => {
    return get("/client/news/getRecommendedNews", json);
},
//获取视频分类信息
getVideoCenterCategory: json => {
    return get("/client/video/center/getVideoCenterCategory", json);
}, 
//分页查询视频
getPageVideo: json => {
    return get("/client/video/center/getPageVideo", json);
}, 
//获取践行承诺
getPracticePromise: json => {
    return get("/client/practice/promise/getPracticePromise", json);
},
// 分页获取公益慈善
getPagePublicCharity: json => {
    return get("/client/public/charity/getPagePublicCharity", json);
},
//获取推荐公益慈善
getRecommendPublicCharity: json => {
    return get("/client/public/charity/getRecommendPublicCharity", json);
}, 
//获取公益慈善详细信息
getPublicCharityInfo: json => {
    return get("/client/public/charity/getPublicCharityInfo", json);
}, 
//分页获取社会责任报告
getSocialResponsibilityReport: json => {
    return get("/client/social/responsibility/report/getSocialResponsibilityReport", json);
}, 
//获取招聘首页信息
getJobHomeInfo: json => {
    return get("/client/job/home/getJobHomeInfo", json);
}, 
//获取职位类别
getJobCategory: json => {
    return get("/client/job/getJobCategory", json);
}, 
//分页获取招聘职位信息
getPageJobRecruitment: json => {
    return get("/client/job/getPageJobRecruitment", json);
}, 
//添加职位申请
addJobInfo: json => {
    return post("/client/job/addJobInfo", json);
},
//获取办公地址
getOfficeAddress: json => {
    return get("/client/office/address/getOfficeAddress", json);
},
//添加客户留言
addClientMessage: json => {
    return post("/client/client/message/addClientMessage", json);
},
//添加媒体留言
addMediaMessage: json => {
    return post("/client/media/message/addMediaMessage", json);
},
//添加媒体留言
addSupplierMessage: json => {
    return post("/client/supplier/message/addSupplierMessage", json);
},

//添加供应商留言
addUserQuestionInfo: json => {
    return post("/client/user/question/info/addUserQuestionInfo", json, 1);
},

//获取香港公司信息
getHongKongCompany: json => {
    return get("/client/company/getHongKongCompany", json);
},

};


export default api;
