import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
        path: "/",
        // path: "*",
        name: "homes",
        redirect: "/index",
        component: resolve => require(["@/views/homes.vue"], resolve),
        children: [
            {
                path: "/index",
                name: "/index",
                component: resolve => require(["@/views/index.vue"], resolve),
                meta: {
                    title: "首页",
                    keepAlive: true,
                }
            },
            {
                path: "/aboutUs/index",
                name: "/aboutUs/index",
                component: resolve => require(["@/views/aboutUs/index.vue"], resolve),
                meta: {
                    title: "我们",
                    keepAlive: true,

                }
            },
            {
                path: "/industry/index",
                name: "/industry/index",
                component: resolve => require(["@/views/industry/index.vue"], resolve),
                meta: {
                    title: "产业",
                    keepAlive: true,

                }
            },
            {
                path: "/industry/caseDetails",
                name: "/industry/caseDetails",
                component: resolve => require(["@/views/industry/caseDetails.vue"], resolve),
                meta: {
                    title: "详情",
                    keepAlive: true,

                }
            },
            {
                path: "/industry/courseDetails",
                name: "/industry/courseDetails",
                component: resolve => require(["@/views/industry/courseDetails.vue"], resolve),
                meta: {
                    title: "详情",
                    keepAlive: true,

                }
            },
            {
                path: "/culture/index",
                name: "culture",
                component: resolve => require(["@/views/culture/index.vue"], resolve),
                meta: {
                    title: "文化",
                    keepAlive: true,

                }
            },
            {
                path: "/culture/cultureDetails",
                name: "cultureDetails",
                component: resolve => require(["@/views/culture/cultureDetails.vue"], resolve),
                meta: {
                    title: "文化详情",
                    keepAlive: true,

                }
            },
            {
                path: "/news/index",
                name: "news",
                component: resolve => require(["@/views/news/index.vue"], resolve),
                meta: {
                    title: "新闻",
                    keepAlive: true,

                }
            }, 
            {
                path: "/news/newsDetails",
                name: "newsDetails",
                component: resolve => require(["@/views/news/newsDetails.vue"], resolve),
                meta: {
                    title: "新闻详情",
                    keepAlive: true,

                }
            }, 
            {
                path: "/responsibility/index",
                name: "responsibility",
                component: resolve => require(["@/views/responsibility/index.vue"], resolve),
                meta: {
                    title: "责任",
                    keepAlive: true,

                }
            },
            {
                path: "/responsibility/charity",
                name: "charity",
                component: resolve => require(["@/views/responsibility/charity.vue"], resolve),
                meta: {
                    title: "慈善详情",
                    keepAlive: true,

                }
            }, 
            {
                path: "/job/index",
                name: "index",
                component: resolve => require(["@/views/job/index.vue"], resolve),
                meta: {
                    title: "招聘",
                    keepAlive: true,

                }
            }, 
            { 
                path: "/contact/index",
                name: "index",
                component: resolve => require(["@/views/contact/index.vue"], resolve),
                meta: {
                    title: "联系",
                    keepAlive: true,

                }
            }, 
            {
                path: "/legalDeclaration/index",
                name: "legalDeclaration",
                component: resolve => require(["@/views/legalDeclaration/index.vue"], resolve),
                meta: {
                    title: "联系",
                    keepAlive: true,

                }
            },
            {
                path: "/reporting/index",
                name: "reporting",
                component: resolve => require(["@/views/reporting/index.vue"], resolve),
                meta: {
                    title: "监察举报",
                    keepAlive: true,

                }
            }, 
            {
                path: "/siteMap/index",
                name: "siteMap",
                component: resolve => require(["@/views/siteMap/index.vue"], resolve),
                meta: {
                    title: "网站地图",
                    keepAlive: true,

                }
            },
            {
                path: "/search/index",
                name: "search",
                component: resolve => require(["@/views/search/index.vue"], resolve),
                meta: {
                    title: "搜索",
                    keepAlive: true,

                }
            },
            {
                path: "/index.html",
                name: "/index.html",
                component: resolve => require(["@/views/index.vue"], resolve),
                meta: {
                    title: "首页",
                    keepAlive: true,
                }
            },
            {
                path: "/aboutUs/index.html",
                name: "/aboutUs/index.html",
                component: resolve => require(["@/views/aboutUs/index.vue"], resolve),
                meta: {
                    title: "我们",
                    keepAlive: true,

                }
            },
            {
                path: "/industry/index.html",
                name: "/industry/index.html",
                component: resolve => require(["@/views/industry/index.vue"], resolve),
                meta: {
                    title: "产业",
                    keepAlive: true,

                }
            },
            {
                path: "/industry/caseDetails.html",
                name: "/industry/caseDetails.html",
                component: resolve => require(["@/views/industry/caseDetails.vue"], resolve),
                meta: {
                    title: "详情",
                    keepAlive: true,

                }
            },
            {
                path: "/industry/courseDetails.html",
                name: "/industry/courseDetails.html",
                component: resolve => require(["@/views/industry/courseDetails.vue"], resolve),
                meta: {
                    title: "详情",
                    keepAlive: true,

                }
            },
            {
                path: "/culture/index.html",
                name: "culture",
                component: resolve => require(["@/views/culture/index.vue"], resolve),
                meta: {
                    title: "文化",
                    keepAlive: true,

                }
            },
            {
                path: "/culture/cultureDetails.html",
                name: "cultureDetails",
                component: resolve => require(["@/views/culture/cultureDetails.vue"], resolve),
                meta: {
                    title: "文化详情",
                    keepAlive: true,

                }
            },
            {
                path: "/news/index.html",
                name: "news",
                component: resolve => require(["@/views/news/index.vue"], resolve),
                meta: {
                    title: "新闻",
                    keepAlive: true,

                }
            },
            {
                path: "/news/newsDetails.html",
                name: "newsDetails",
                component: resolve => require(["@/views/news/newsDetails.vue"], resolve),
                meta: {
                    title: "新闻详情",
                    keepAlive: true,

                }
            },
            {
                path: "/responsibility/index.html",
                name: "responsibility",
                component: resolve => require(["@/views/responsibility/index.vue"], resolve),
                meta: {
                    title: "责任",
                    keepAlive: true,

                }
            },
            {
                path: "/responsibility/charity.html",
                name: "charity",
                component: resolve => require(["@/views/responsibility/charity.vue"], resolve),
                meta: {
                    title: "慈善详情",
                    keepAlive: true,

                }
            },
            {
                path: "/job/index.html",
                name: "index",
                component: resolve => require(["@/views/job/index.vue"], resolve),
                meta: {
                    title: "招聘",
                    keepAlive: true,

                }
            },
            {
                path: "/contact/index.html",
                name: "index",
                component: resolve => require(["@/views/contact/index.vue"], resolve),
                meta: {
                    title: "联系",
                    keepAlive: true,

                }
            },
            {
                path: "/legalDeclaration/index.html",
                name: "legalDeclaration",
                component: resolve => require(["@/views/legalDeclaration/index.vue"], resolve),
                meta: {
                    title: "联系",
                    keepAlive: true,

                }
            },
            {
                path: "/reporting/index.html",
                name: "reporting",
                component: resolve => require(["@/views/reporting/index.vue"], resolve),
                meta: {
                    title: "监察举报",
                    keepAlive: true,

                }
            },
            {
                path: "/siteMap/index.html",
                name: "siteMap",
                component: resolve => require(["@/views/siteMap/index.vue"], resolve),
                meta: {
                    title: "网站地图",
                    keepAlive: true,

                }
            },
            {
                path: "/search/index.html",
                name: "search",
                component: resolve => require(["@/views/search/index.vue"], resolve),
                meta: {
                    title: "搜索",
                    keepAlive: true,

                }
            },
            {
                path: "/HongKongGER/index",
                name: "HongKongGER",
                component: resolve => require(["@/views/HongKongGER/index.vue"], resolve),
                meta: {
                    title: "香港果尔佳服务有限公司",
                    keepAlive: true,

                }
            },
            {
                path: "*",
                name: "/index.html",
                component: resolve => require(["@/views/index.vue"], resolve),
                meta: {
                    title: "首页",
                    keepAlive: true,
                }
            },
           
           
        ]
  },
 
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
