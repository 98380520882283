import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/style/animate.min.css"
import "./assets/style/animations.css"
import "./assets/style/default-skin.css"
import "./assets/style/index.css"
import "./assets/style/main.css"
import "./assets/style/media.css"
import "./assets/style/photoswipe.css"
import "./assets/style/Sdialog.css"
// import "./assets/style/swiper.min.css"
import js from "./assets/script/js";
import 'wowjs/css/libs/animate.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import api from "./http/api";
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
Vue.use(preview)
Vue.prototype.api = api;
Vue.use(ElementUI);
Vue.config.productionTip = false
    // 滚动动画 wow.js
import { WOW } from 'wowjs'
Vue.prototype.$wow = new WOW({
    boxClass: 'wow', // default
    animateClass: 'animated', // default
    offset: 150, // default
    mobile: true, // default
    live: false,

    // live为true时，控制台会提示：MutationObserver is not supported by your browser. & WOW.js cannot detect dom mutations, please call .sync() after loading new content.

    callback: function (box) {
     
    }
})

Vue.config.productionTip = false

Vue.prototype.js = js; //公共方法
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
