import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const state = {
    activeIndex: 0,
    menuData:[]

};

//导航选中
if (
    localStorage.getItem("activeIndex") != null &&
    localStorage.getItem("activeIndex") != undefined
) {
    state.activeIndex = JSON.parse(localStorage.getItem("activeIndex"));
}
//菜单
if (
    localStorage.getItem("menuData") != null &&
    localStorage.getItem("menuData") != undefined
) {
    state.menuData = JSON.parse(localStorage.getItem("menuData"));
}
const mutations = {
    //导航选中
    getActiveIndex(state, data) {
        state.activeIndex = data
        localStorage.setItem("activeIndex", JSON.stringify(data))
    },
    //菜单
    getMenu(state, data) {
        state.menuData = data
        localStorage.setItem("menuData", JSON.stringify(data))
    },

};
const getters = {};
const modules = {};
export default new Vuex.Store({
    state,
    mutations,
    getters,
    modules,
});