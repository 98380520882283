// let fontSize = function () {
//     var size;
//     var winW = document.documentElement.clientWidth;
//     if (winW <= 1640 && winW > 800) {
//         size = Math.round(winW / 16.4);
//     } else if (winW <= 800) {
//         size = Math.round(winW / 7.5);
//         if (size > 65) {
//             size = 65;
//         }
//     } else {
//         size = 100;
//     }
//     document.querySelector('html').style.fontSize = size + 'px';
// }
// window.addEventListener('resize', fontSize);

(function (document, win) {

    var size;
    var docElement = document.documentElement,

        resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',

        recalc = function () {

            var clientWidth = docElement.clientWidth;

            if (!clientWidth) return;
            if (clientWidth <= 1640 && clientWidth > 800) {
                size = Math.round(clientWidth / 24);
            } else if (clientWidth <= 800) {
                size = Math.round(clientWidth / 7.5);
                if (size > 65) {
                    size = 65;
                }
            } else {
                size = 100;
            }
            document.querySelector('html').style.fontSize = size + 'px';

        };


    if (!document.addEventListener) return;

    win.addEventListener(resizeEvt, recalc, false);

    document.addEventListener('DOMContentLoaded', recalc, false);

})(document, window);