<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    mounted() {
        this.$nextTick(() => {
            this.$wow.init();
        });
        this.init()

    },
    methods: {
        init() {
            this.api.getPlatformInfo().then(res => {
                document.querySelector('meta[name="keywords"]').setAttribute('content', res.data.siteKeyword)
                document.querySelector('meta[name="description"]').setAttribute('content', res.data.siteDescription)
            })
        },
    },

}
</script>
<style lang="scss">
html,
body,
#app {
    height: 100%;
}
</style>
